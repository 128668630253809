main {
	display: flex !important;
	flex-direction: column;
	justify-content: center;
	width: 100vw;
	height: 100vh;
	color: white;
	background-color: rgb(15, 15, 15);
	font-family: 'Work Sans';
	--regular-font-size: 16px;
	--large-font-size: 61px;
}

* {
	-webkit-tap-highlight-color: transparent;
}

.routes {
	width: 100%;
	display: flex;
	justify-content: center;
	position: relative;
}

main .routes {
	height: calc(100vh - 200px);
}

.routes > div {
	width: 100%;
	display: flex;
	justify-content: center;
	position: absolute;
}

a {
	display: flex;
	width: fit-content;
	text-decoration: none; /* Remove underline */
	color: inherit; /* Inherit text color */
}

a:hover,
a:active,
a:focus {
	text-decoration: none; /* Remove underline on hover, active, and focus */
	outline: none; /* Removes the default focus outline */
	background-color: transparent; /* Sets background color to transparent */
}

section {
	width: 100%;
	height: calc(100vh - 280px);
	max-width: 1200px;
	padding: 40px 100px;
	position: relative;
}

.italic {
	font-style: italic;
}

.underline {
	text-decoration: underline;
}

.bold {
	font-weight: bold;
}

@media screen and (max-width: 1025px) {
	main {
		--regular-font-size: 13px;
		--large-font-size: 39px;
	}
}

@media screen and (min-width: 500px) and (max-height: 700px) {
	main .routes {
		min-height: 600px;
	}

	section {
		min-height: 800px;
	}
}

@media screen and (max-width: 500px) {
	header {
		display: none;
	}

	main .routes {
		height: calc(100vh - 52px);
	}

	section {
		padding: 15px;
		box-sizing: border-box;
		height: calc(100vh - 52px);
	}
}
