.connect {
	display: flex;
}

.connect-box {
	width: 50%;
}

.connect-title {
	position: relative;
    font-size: var(--large-font-size);
	font-weight: bold;
	list-style: 1;
	font-family: 'Georgia';
	z-index: 1;
}

.connect-text {
	margin-top: 20px;
	text-align: justify;
	font-size: var(--regular-font-size);
}

.connect-info {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	margin-left: 40px;
	padding-left: 40px;
	height: 100%;
}

.connect-info a:hover {
	text-decoration: underline;
}

.connect-info-item-title {
	font-size: var(--regular-font-size);
	margin-top: 40px;
}

.connect-info-item-text {
	margin-top: 10px;
	text-decoration: underline;
	font-size: 24px;
	font-weight: bold;
	cursor: pointer;
}

@media screen and (max-width: 500px) {
	.connect {
		flex-direction: column;
	}

	.connect-box {
		width: 100%;
	}

	.connect-title {
		width: auto;
		position: relative;
		top: unset;
		right: unset;
		left: unset;
	}

	.connect-info-item-title {
		margin-top: 20px;
	}

	.connect-info {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-left: 0px;
		padding-left: 0px;
		padding-bottom: 48px;
	}

	.connect-info-item-text {
		font-size: 16px;
	}
}