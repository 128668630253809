header {
	height: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.header-content {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 40px 100px;
	max-width: 1200px;
}

.header-items {
	display: flex;
	font-weight: 600;
}

.header-item-active {
	color: rgb(255, 174, 0);
}

.header-items a {
	margin-left: 20px;
	font-size: var(--regular-font-size);
}

.header-logo {
	display: flex;
	align-items: center;
	font-weight: 600;
	user-select: none;
	font-size: 11px;
	line-height: 1;
}

.header-logo img {
	height: 20px;
	margin-right: 5px;
}

@media screen and (max-width: 500px) {
	header {
		height: unset;
	}

	.header-content{
		padding: 15px;
	}

	.header-items {
		display: none;
	}
}