.backdrop-image {
	display: flex;
	justify-content: flex-end;
	position: relative;
}

.backdrop-line {
	width: 40px;
	height: 5px;
	background-color: rgb(255, 174, 0);
	margin-bottom: 20px;
}

.backdrop-title {
	font-size: var(--large-font-size);
	font-weight: bold;
	font-family: 'Georgia';
	position: absolute;
	left: 0;
}

.backdrop-image img {
	height: 200px;
	width: 490px;
	object-fit: cover;
	object-position: top;
}

.backdrop-content {
	display: flex;
	margin-top: 40px;
}

.backdrop-options {
	margin-right: 40px;
}

.backdrop-option-inactive {
	font-size: var(--regular-font-size);
	cursor: pointer;
	margin-bottom: 10px;
	font-weight: 600;
}

.backdrop-option-active {
	font-size: var(--regular-font-size);
	font-weight: 600;
	color: rgb(255, 174, 0);
	cursor: pointer;
	margin-bottom: 10px;
}

.backdrop-text {
	font-size: var(--regular-font-size);
	text-align: justify;
}

@media screen and (max-width: 500px) {
	.backdrop-line {
		height: 2px;
	}

	.backdrop-image {
		flex-direction: column;
	}

	.backdrop-title {
		position: relative;
	}

	.backdrop-content {
		flex-direction: column;
		margin-top: 20px;
	}

	.backdrop-image img {
		width: 100%;
		height: 160px;
		margin-top: 20px;
	}

	.backdrop-options {
		display: flex;
	}

	.backdrop-option-inactive,
	.backdrop-option-active {
		margin-right: 15px;
		margin-bottom: 15px;
	}

	.backdrop-option-active {
		border-bottom: 2px solid;
	}

	.backdrop-text {
		padding-bottom: 55px;
	}
}