.error-title {
	font-size: var(--large-font-size);
	font-weight: bold;
	font-family: 'Georgia';
	margin: 20px 0px;
}

.error-cta {
	width: fit-content;
	font-weight: 600;
	font-size: var(--regular-font-size);
	color: black;
	background-color: rgb(255, 174, 0);
	padding: 10px 20px;
	border-radius: 20px;
	margin-top: 40px;
	cursor: pointer;
}

.error-line {
	width: 40px;
	height: 5px;
	background-color: rgb(255, 174, 0);
	margin-bottom: 20px;
}
