.career {
	display: flex;
}

.career-image {
	margin: -40px 0;
}

.career-image img {
	height: 100%;
	max-height: 800px;
	width: 275px;
	object-fit: cover;
	object-position: right;
}

.career-section {
	margin-bottom: 40px;
}

.career-content {
	margin-left: 40px;
}

.career-line {
	width: 40px;
	height: 5px;
	background-color: rgb(255, 174, 0);
	margin-bottom: 10px;
}

.career-title {
	font-size: 39px;
	font-weight: bold;
	font-family: 'Georgia';
	margin-bottom: 20px;
	z-index: 1;
}

.career-item {
	display: flex;
	margin-bottom: 20px;
}

.career-left {
	min-width: 100px;
	margin-right: 20px;
}

.career-item-title {
	font-weight: bold;
}

.career-item-subtitle {
	font-size: var(--regular-font-size);
	display: flex;
	line-height: 1;
}

.career-positions .career-item-subtitle {
	margin-bottom: 5px;
}

.career-item-subtitle span {
	color: #aeaeae;
	margin-left: 5px;
}

.career-item-description {
	font-size: var(--regular-font-size);
	text-align: justify;
	margin: 10px 0px;
}

.career-positions {
	display: flex;
}

.career-positions-left {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-right: 5px;
	margin-top: -5px;
}

.career-positions-circle {
	background-color: white;
	width: 8px;
	height: 8px;
}

.career-positions-line {
	height: 13px;
	background-color: white;
	width: 1px;
}

@media screen and (max-width: 500px) {
	.career-line {
		height: 2px;
	}

	.career-image {
		display: none;
	}

	.career-content {
		margin-left: 0px;
	}

	.career-section:last-of-type {
		padding-bottom: 48px;
	}

	.career-positions .career-item-subtitle {
		margin-bottom: 7px;
	}
}