.home-line {
	width: 40px;
	height: 5px;
	background-color: rgb(255, 174, 0);
	margin-bottom: 20px;
}

.home-title {
	position: relative;
    font-size: var(--large-font-size);
	font-weight: bold;
	list-style: 1;
	font-family: 'Georgia';
	z-index: 1;
}

.home-image {
	position: absolute;
	top: 0px; right: 100px;
	bottom: 0px;
}

.home-image img {
	opacity: 0.8;
	max-height: 600px;
	height: 100%;
}

.home-text {
	position: relative;
	max-width: 600px;
	font-size: var(--regular-font-size);
	z-index: 1;
}

.home-text strong {
	cursor: pointer;
}

.home-cta {
	width: fit-content;
	font-weight: 600;
	font-size: var(--regular-font-size);
	color: black;
	background-color: rgb(255, 174, 0);
	padding: 10px 20px;
	border-radius: 20px;
	margin-top: 40px;
	cursor: pointer;
}

.home-image-mobile {
	display: none;
}

@media screen and (max-width: 1200px) and (min-width: 500px){
	.home img {
		opacity: 0.5;
	}
}

@media screen and (max-width: 800px){
	.home-text {
		font-size: 13px;
	}
}

@media screen and (max-width: 500px){
	.home-line {
		height: 2px;
	}

	.home-title {
		width: 50%;
		position: absolute;
		top: 40px;
		right: 15px;
		left: 15px;
	}

	.home-image-desktop {
		display: none;
	}

	.home-image-mobile-box {
		position: relative;
		width: 70%;
	}

	.home-image-mobile {
		display: block;
		object-fit: cover;
		object-position: right;
		width: 100%;
	}

	.home-image {
		display: flex;
		justify-content: flex-end;
		position: relative;
		right: 0px;
	}

	.home-image-overlay {
		position: absolute;
		inset: 0px;
		background-color: rgba(0, 0, 0, 0.3);
	}

	.home img {
		height: 40vh;
	}

	.home-cta {
		margin-top: 20px;
	}
}

@media screen and (max-height: 800px){
	.home-title {
		width: 90%;
	}
}

@media screen and (max-height: 670px){
	.home {
		margin-bottom: 20px;
	}
}