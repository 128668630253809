footer {
	height: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.footer-desktop-content {
	width: 100%;
	max-width: 1200px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 40px 100px;
	font-size: var(--regular-font-size);
}

.footer-mobile-content {
	display: none;
}

.footer-desktop-languages {
	font-weight: 600;
}

.footer-language-divider {
	margin: 0 5px;
}

.footer-language {
	cursor: pointer;
}

@media screen and (max-width: 500px){
	footer {
		position: fixed;
		bottom: 0px;
		width: 100%;
		height: auto;
		z-index: 1;
		background-color: rgba(0,0,0,0.9);
	}

	.footer-mobile-content {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		box-sizing: border-box;
		padding: 15px;
	}

	.footer-desktop-content {
		display: none;
	}

	.footer-mobile-box {
		display: flex;
		align-items: center;
	}

	.footer-mobile-step {
		width: 15px;
		height: 3px;
		border-radius: 2px;
		background-color: rgba(255, 255, 255, 0.5);
		margin-right: 5px;
	}

	.footer-mobile-step-active {
		width: 15px;
		height: 3px;
		border-radius: 2px;
		background-color: rgba(255, 255, 255, 1);
		margin-right: 5px;
	}
	
	.footer-mobile-back,
	.footer-mobile-forward {
		display: flex;
		align-items: center;
		width: 70px;
		font-size: var(--regular-font-size);
		font-weight: bold;
	}

	.footer-mobile-forward {
		justify-content: flex-end;
	}

	.footer-mobile-content .material-symbols-outlined {
		font-size: 18px;
	}
}